import React from 'react'

import SEO from '../../components/seo'
import Layout from '../../components/layout'
import HeaderImg from '../../components/HeaderImg/HeaderImg'
import ProjectOverview from '../../components/ProjectOverview/ProjectOverview'
import TextBlock from '../../components/TextBlock/TextBlock'
import BottomNav from '../../components/BottomNav/BottomNav'
import TestimonialVideo from '../../components/TestimonialVideo/TestimonialVideo'
import { JavascriptIcon } from '../../components/icons/JavascriptIcon'
import { HtmlIcon } from '../../components/icons/HtmlIcon'
import { WordPressIcon } from '../../components/icons/WordPressIcon'
import Logo from '../../images/beard-and-bowler-logo.webp'

// import Testimonial from '../../components/Testimonial/Testimonial'
// import Slider from '../../components/Slider/Slider'
// import Goals from '../../components/Goals/Goals'
// import Outcomes from '../../components/Outcomes/Outcomes'

const BeardBowlerPage = () => {
  const services = [
    {
      id: 1,
      text: 'Website Design',
    },
    {
      id: 2,
      text: 'Project Management',
    },
    {
      id: 3,
      text: 'WordPress Development',
    },
  ]

  // const goals = [
  //   {
  //     id: 11,
  //     text:
  //       'Our goal was to help the client develop a modern and scalable marketplace.',
  //   },
  //   {
  //     id: 22,
  //     text:
  //       'Then we needed to integrate the marketplace with the client’s Salesforce CRM account, Stripe for collecting payments, and set up automated email notifications.',
  //   },
  //   {
  //     id: 33,
  //     text:
  //       'We also needed to develop a dedicated admin panel so the client can manage the content themselves.',
  //   },
  // ]

  // const outcomes = [
  //   {
  //     id: 111,
  //     title: '24',
  //     subtitle: 'months of ongoing partnership',
  //   },
  //   {
  //     id: 222,
  //     title: '700+',
  //     subtitle: 'active platform users',
  //   },
  //   {
  //     id: 333,
  //     title: '3',
  //     subtitle: 'platforms integrated with the client’s new platform (X,Y,Z)',
  //   },
  //   {
  //     id: 444,
  //     title: '7',
  //     subtitle: 'processes automated',
  //   },
  //   {
  //     id: 555,
  //     title: '',
  //     subtitle:
  //       'a dedicated admin panel where client can manage the content themselves',
  //   },
  // ]

  // const slides = [
  //   {
  //     id: 1,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-4.jpg',
  //     alt: 'Emersoft Team with Brad Lea in LighSpeed VT office',
  //   },
  //   {
  //     id: 2,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-2.jpg',
  //     alt: 'Emersoft Team in LightSpeed VT office',
  //   },
  //   {
  //     id: 3,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-3.jpg',
  //     alt: 'Emersoft Team visiting client in Las Vegas',
  //   },
  //   {
  //     id: 4,
  //     img:
  //       'https://cdn.emersoft.co/emersoft-website/lightspeed/lsvt-slider-1.jpg',
  //     alt: 'LighSpeed VT office',
  //   },
  // ]

  const techs = [
    {
      id: 1111,
      icon: JavascriptIcon(),
    },
    {
      id: 6666,
      icon: WordPressIcon(),
    },
    {
      id: 2222,
      icon: HtmlIcon(),
    },
  ]

  return (
    <Layout>
      <SEO
        title="Beard & Bowler - Case Study - Emersoft"
        keywords={[
          `Emersoft`,
          `application`,
          `react`,
          `London`,
          `digital agency`,
          `Beard & Bowler`,
          `Angular`,
        ]}
      />
      <HeaderImg
        header="header-img--beardBowler"
        img={Logo}
        wrapper="header-img__img-wrapper--beardBowler"
      />
      <ProjectOverview
        title="Beard & Bowler"
        link="https://beardandbowler.com/"
        category='Non-Profit'
        services={services}
        technologies={techs}
        subtitle="How A New Branded & User Experience- Optimized Website Helps Beard & Bowler Turn Potential Into Paying Clients "
        paragraph1="Beard & Bowler are New Jersey-based, master story-tellers specialized in non-profit storytelling & video production services. Their mission is to spark change, forge bonds, and elicit hope using professional, emotion-laden videos that speak from the(ir) heart to the heart of an audience. "
      />
      <TestimonialVideo
        url="https://www.youtube.com/watch?v=-1mLLl5cTrU"
        author="Beard & Bowler"
        authorRole="Brand Owner"
        thumbnail="https://cdn.emersoft.co/emersoft-website/beard-and-bowler/beard-and-bowler-video-thumbnail.png"
        alt="Beard & Bowler"
      />
      <TextBlock
        heading="Brief"
        paragraph1="The client needed a website that would help them make a great first impression on prospective clients and that would be more consistent with their branding. From the technical perspective, the goal was to improve the user interface so the website loads faster and works well on mobile. The client also wanted to make their website SEO-optimized.
"
        paragraph2="Last but not least, Beard & Bowler needed a page where potential clients could use email opt-in feature to receive their “secret sauce storytelling formula” (a video presentation showcasing their skills and brand)   "
      />
      {/* <Slider slides={slides} /> */}
      {/* <Goals goalsList={goals} /> */}
      <TextBlock
        heading="Our Process"
        paragraph1="We first had a discovery call to establish what the client needed. Then we sent a proposal and got the agreement to kick-off.  After starting the design process, we showed it to the client for approval on a number of Zoom calls. Once we got a green light, we started the development work and delivered on time and budget. Our collaboration was 100% online."
      />
      <TextBlock
        heading="Results"
        paragraph1="The client got a brand new website that’s faster, SEO-optimized and easier to navigate while remaining on-line with their branding. Visitors now enjoy a premium-feel, user-friendly layout. With a new website that better conveys the value of their brand & services, Beard & Bowler reports more contacts and inquiries from potential clients."
        paragraph2="We also set up client’s email on Google Suite and Google Analytics Dashboard which helped make their daily business activities quicker and hassle-free."
      />
      {/* <Outcomes outcomes={outcomes} /> */}
      <BottomNav
        heading="See More"
        firstLink="/our-work/vooba"
        firstHeading="Vooba"
        secondLink="/our-work/radium"
        secondHeading="Radium"
      />
    </Layout>
  )
}

export default BeardBowlerPage
